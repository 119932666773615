/* eslint-disable */
(function () {
  'use strict';

  function teamModuleConfig($stateProvider) {
    $stateProvider
      .state('root.platform.team', {
        abstract: true,
        url: '/team/:id',
        views: {
          'tabcontent@root.platform': {
            templateUrl: 'team/views/_layout.html',
            controller: 'TeamController',
            controllerAs: '$teamCtrl',
          },
          'team-facts-view@root.platform.team': {
            templateUrl: 'team/views/facts.html',
            controller: 'TeamInfoController',
            controllerAs: 'info',
          },
          'team-videos-view@root.platform.team': {
            template: '<team-videos team="$teamCtrl.details" season="$teamCtrl.gameSeason" />',
          },
          'team-schedule-view@root.platform.team': {
            templateUrl: 'team/views/schedule.html',
            controller: 'TeamScheduleController',
            controllerAs: '$scheduleCtrl',
          },
          'team-playlist-subview@root.platform.team': {
            template: '<team-playlists team="$teamCtrl.details" season="$teamCtrl.gameSeason" />',
          },
        },
        resolve: {
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, [
              '/app/js/team/sources.js',
              '/app/js/player/sources.js',
              '/app/js/game/sources.js',
              '/app/js/communication/sources.js',
              '/app/js/admin/sources.js',
              '/app/js/competition/sources.js',
              '/app/js/scouting/sources.js',
              '/app/js/advanced-analytics/sources.js',
            ]);
          },
        },
      })
      .state('root.platform.team.overview', {
        url: '',
        redirectTo: 'root.platform.team.overview.roster',
        abstract: true,
        views: {
          '@root.platform.team': {
            templateUrl: 'team/views/overview.html',
          },
        },
      })
      .state('root.platform.team.contacts', {
        url: '/contacts',
        views: {
          '@root.platform.team': {
            template:
              '<div>' +
              '<team-contacts team="$teamCtrl.details" leagues="$teamCtrl.leagues" />' +
              '<div class="hidden">' +
              '<gamestats-query-selector is-disabled="!$teamCtrl.overallStatsVisible" games="$teamCtrl.games" on-change="$teamCtrl.handleStatsQueryChange($seasonId, $timeQuery, $games)" />' +
              '</div>' +
              '</div>',
          },
        },
      })
      .state('root.platform.team.overview.roster', {
        url: '',
        views: {
          'team-details-roster-view@root.platform.team.overview': {
            templateUrl: 'team/views/overview/roster.html',
            controller: 'TeamRosterController',
            controllerAs: 'roster',
          },
        },
      })
      .state('root.platform.team.overview.clubstats', {
        url: '/club-stats',
        views: {
          'team-details-clubstats-view@root.platform.team.overview': {
            templateUrl: 'team/views/overview/club-stats.html',
            controller: 'TeamClubStatsController',
            controllerAs: '$ctrl',
          },
        },
      })
      .state('root.platform.team.overview.playerstats', {
        url: '/player-stats',
        views: {
          'team-details-playerstats-view@root.platform.team.overview': {
            templateUrl: 'team/views/overview/player-stats.html',
            controller: 'TeamPlayerStatsController',
            controllerAs: 'players',
          },
        },
      })
      .state('root.platform.team.overview.stats', {
        url: '/stats',
        views: {
          'team-details-stats-view@root.platform.team.overview': {
            templateUrl: 'team/views/overview/stats.html',
            controller: 'TeamStatsController',
            controllerAs: 'players',
          },
        },
      })
      .state('root.platform.team.overview.stats_by_game', {
        url: '/stats-by-game',
        views: {
          'team-details-stats-by-game-view@root.platform.team.overview': {
            template:
              '<team-statistics-by-game team="$teamCtrl.details" team-events="$teamCtrl.gameEvents" range="$teamCtrl.timeQuery" strength-filter="$teamCtrl.strengthFilter" ' +
              'season="$teamCtrl.gameSeason" stats="$teamCtrl.gameStats" ' +
              'loading-promise="$teamCtrl.$gameStatsPromise" ' +
              'on-play-game="$teamCtrl.playGame($homeId, $awayId, $date)"' +
              'on-play-events="$teamCtrl.playEvents($gameEvents, $title)" />',
          },
        },
      })
      .state('root.platform.team.overview.captains', {
        url: '/captains',
        views: {
          'team-details-captains-view@root.platform.team.overview': {
            templateUrl: 'team/views/overview/captains.html',
            controller: 'TeamCaptainsController',
            controllerAs: 'captains',
          },
        },
      })
      .state('root.platform.team.overview.staff', {
        url: '/staff',
        views: {
          'team-details-staff-view@root.platform.team.overview': {
            templateUrl: 'team/views/overview/staff.html',
            controller: 'TeamStaffController',
            controllerAs: 'staff',
          },
        },
      })
      .state('root.platform.team.overview.arena', {
        url: '/arena',
        views: {
          'team-details-arena-view@root.platform.team.overview': {
            templateUrl: 'team/views/overview/arena.html',
            controller: 'TeamArenaController',
            controllerAs: 'arena',
          },
        },
      })
      .state('root.platform.team.overview.season_best_players', {
        url: '/season-best-players',
        views: {
          'team-details-season_best_players-view@root.platform.team.overview': {
            templateUrl: 'team/views/overview/season-best-players.html',
            controller: 'TeamSeasonBestPlayersController',
            controllerAs: 'players',
          },
        },
      })
      .state('root.platform.team.overview.top_players', {
        url: '/top-players',
        views: {
          'team-details-top_players-view@root.platform.team.overview': {
            templateUrl: 'team/views/overview/top-players.html',
            controller: 'TeamTopPlayersController',
            controllerAs: 'players',
          },
        },
      })
      .state('root.platform.team.overview.stars', {
        url: '/stars',
        views: {
          'team-details-stars-view@root.platform.team.overview': {
            templateUrl: 'team/views/overview/stars.html',
            controller: 'TeamStarsController',
            controllerAs: 'players',
          },
        },
      })
      .state('root.platform.team.overview.where_they', {
        url: '/where-are-they-now',
        views: {
          'team-details-where_they-view@root.platform.team.overview': {
            templateUrl: 'team/views/overview/where-they.html',
            controller: 'TeamWhereTheyController',
            controllerAs: 'players',
          },
        },
      })
      .state('root.platform.team.overview.transfers', {
        url: '/transfers',
        views: {
          'team-details-transfers-view@root.platform.team.overview': {
            templateUrl: 'team/views/overview/transfers.html',
            controller: 'TeamTransfersController',
            controllerAs: 'transfers',
          },
        },
      })
      .state('root.platform.team.not_found', {
        url: '',
        views: {
          'tabcontent@root.platform': {
            template: '<sc-not-found title="Team"></sc-not-found>',
          },
        },
      });
  }

  var teamModule = angular.module('app.team', [
    'app.common',
    'app.general',
    'ui.router',
    'ui.router.router',
    'pascalprecht.translate',
    'app.advancedAnalytics',
  ]);
  teamModule.config(teamModuleConfig);
})();
